// src/components/Career.js

import React from 'react';
import './Career.css';
import careerImage1 from '../assets/3.jpg'; 
import careerImage2 from '../assets/4.jpg'; 

const Career = () => {
    return (
        <div className="career-container">
            <h1 className="career-title">Join Our Winning Team</h1>
            
            <div className="career-section">
                <div className="career-image-container">
                    <img src={careerImage1} alt="Success Journey" className="career-image" />
                </div>
                <div className="career-text">
                    <p>At Kolgjini Consulting, we are always on the lookout for talented and driven individuals to strengthen our team. Our success stems from the amazing people we work with, and we deeply value the unique contributions they make to our organization.</p>
                    <p>As we continue to grow and take on exciting new challenges, we understand the importance of bringing on board individuals who not only excel in their craft but also share our core values. We are committed to building a diverse and inclusive environment where everyone can thrive, grow, and make a lasting impact.</p>
                </div>
            </div>

            <div className="career-section reverse">
                <div className="career-image-container">
                    <img src={careerImage2} alt="Career Growth" className="career-image" />
                </div>
                <div className="career-text">
                    <p>If you're innovative, ambitious, and eager to make a real difference, Kolgjini Consulting could be the perfect place for you. We foster a collaborative culture that encourages personal and professional growth, giving you the chance to work on impactful projects with dynamic teams.</p>
                    <p>Our commitment to our employees' development and well-being is paramount. We offer extensive learning opportunities, competitive benefits, and a supportive work environment that promotes balance between personal and professional life. Join us and be a part of something exceptional, where your contributions are valued and your success is our priority.</p>
                </div>
            </div>
        </div>
    );
};

export default Career;
