import React from 'react';
import { Link } from 'react-router-dom';
import './404.css';  // Importing the CSS for styling

const NotFound = () => {
    return (
        <div className="not-found">
            <div className="not-found-content">
                <h1 className="not-found-title">404</h1>
                <h2>Oops! Page not found</h2>
                <p>We can't seem to find the page you're looking for.</p>
                
                <div className="not-found-actions">
                    <Link to="/" className="btn">Go back to Home</Link>
                    <Link to="/assignments" className="btn">View Assignments</Link>
                </div>
            </div>
        </div>
    );
};

export default NotFound;
