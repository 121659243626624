// src/components/Footer.js
import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
    return (
        <footer className="custom-footer text-white text-center text-lg-start">
            <div className="container p-4">
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-4 mb-md-0">
                        <h5 className="text-uppercase">Kolgjini Consulting</h5>
                        <p>
                            Providing expert consulting and recruitment services to help your business thrive in today’s competitive environment.
                        </p>
                    </div>

                    <div className="col-lg-2 col-md-6 mb-4 mb-md-0">
                        <h5 className="text-uppercase">Quick Links</h5>
                        <ul className="list-unstyled">
                            <li><Link to="/" className="text-white">Home</Link></li>
                            <li><Link to="/assignments" className="text-white">Assignments</Link></li>
                            <li><Link to="/career" className="text-white">Career</Link></li>
                        </ul>
                    </div>

                    <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
                        <h5 className="text-uppercase">Contact Information</h5>
                        <ul className="list-unstyled">
                            <li>
                                <span>Address: Hyllie Stationstorg 31,</span><br />
                                <span>215 32 Malmö, Sweden</span>
                            </li>
                            <li>
                                <span>Phone: <a href="tel:+46729235555" className="text-white">+46 72 923 5555</a></span>
                            </li>
                            <li>
                                <span>Email: <a href="mailto:ek@kconsult.se" className="text-white">ek@kconsult.se</a></span>
                            </li>
                        </ul>
                    </div>

                    <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
                        <h5 className="text-uppercase">Follow Us</h5>
                        <div className="social-icons">
                            <a href="https://www.linkedin.com/company/kconsultse" className="text-white me-3">
                                <FontAwesomeIcon icon={faLinkedin} className="social-icon" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="text-center p-3" style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}>
                © 2024 Kolgjini Consulting. All rights reserved.
            </div>
        </footer>
    );
};

export default Footer;
