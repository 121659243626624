import React, { useState, useEffect } from 'react';
import './SlidingDrawer.css';

const SlidingDrawer = ({ isOpen, closeDrawer, assignment }) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [availability, setAvailability] = useState('');
    const [rate, setRate] = useState('');
    const [message, setMessage] = useState('');
    const [cv, setCv] = useState(null);
    const [captcha, setCaptcha] = useState('');
    const [captchaAnswer, setCaptchaAnswer] = useState('');
    const [loading, setLoading] = useState(false);
    const [statusMessage, setStatusMessage] = useState('');

    useEffect(() => {
        // Fetch a new CAPTCHA when the drawer opens
        if (isOpen) {
            fetch('https://kconsult.se/server/generate_captcha.php')
                .then(response => response.json())
                .then(data => setCaptcha(data.problem))
                .catch(err => console.error('Error fetching CAPTCHA:', err));
        }
    }, [isOpen]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        // Create form data to send to backend
        const formData = new FormData();
        formData.append('name', name);
        formData.append('email', email);
        formData.append('availability', availability);
        formData.append('rate', rate);
        formData.append('message', message);

        // Ensure assignmentId is properly passed
        if (!assignment || !assignment.id) {
            setStatusMessage('Assignment ID is missing.');
            setLoading(false);
            return;
        }
        formData.append('assignmentId', assignment.id);
        formData.append('cv', cv);
        formData.append('captchaAnswer', captchaAnswer);

        // **LOG FORM DATA** before sending
        for (let pair of formData.entries()) {
            console.log(`${pair[0]}: ${pair[1]}`);
        }

        try {
            const response = await fetch('https://kconsult.se/server/apply.php', {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                setStatusMessage('Application submitted successfully!');
                closeDrawer();  // Optionally close drawer after submission
            } else {
                const errorData = await response.json();
                setStatusMessage(`Failed to submit application: ${errorData.error}`);
            }
        } catch (error) {
            console.error('Error submitting application:', error);
            setStatusMessage('An error occurred while submitting the application.');
        }

        setLoading(false);
    };

    return (
        <div className={`sliding-drawer ${isOpen ? 'open' : ''}`}>
            <div className="drawer-content">
                <h3>Apply for {assignment?.title}</h3>
                <form onSubmit={handleSubmit} encType="multipart/form-data">
                    <label>
                        Name*:
                        <input type="text" value={name} onChange={(e) => setName(e.target.value)} required />
                    </label>
                    <label>
                        Email*:
                        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                    </label>
                    <label>
                        Availability*:
                        <input type="date" value={availability} onChange={(e) => setAvailability(e.target.value)} required />
                    </label>
                    <label>
                        Rate:
                        <input type="number" value={rate} onChange={(e) => setRate(e.target.value)} />
                    </label>
                    <label>
                        Message*:
                        <textarea value={message} onChange={(e) => setMessage(e.target.value)} required />
                    </label>
                    <label>
                        Attach CV:
                        <input type="file" onChange={(e) => setCv(e.target.files[0])} />
                    </label>

                    {/* Custom CAPTCHA */}
                    <label>
                        Solve: {captcha}
                        <input
                            type="text"
                            value={captchaAnswer}
                            onChange={(e) => setCaptchaAnswer(e.target.value)}
                            required
                        />
                    </label>

                    <div className="drawer-buttons">
                        <button type="submit" disabled={loading}>
                            {loading ? 'Submitting...' : 'Submit'}
                        </button>
                        <button type="button" onClick={closeDrawer} disabled={loading}>
                            Cancel
                        </button>
                    </div>
                </form>

                {statusMessage && <p className="status-message">{statusMessage}</p>}
            </div>
        </div>
    );
};

export default SlidingDrawer;
