import React, { useState, useEffect } from 'react';
import './Assignments.css';
import SlidingDrawer from '../components/SlidingDrawer';  // Import SlidingDrawer component

const Assignments = () => {
    const [assignmentsData, setAssignmentsData] = useState([]);  // State to hold fetched assignments
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedJobType, setSelectedJobType] = useState('');
    const [selectedLocation, setSelectedLocation] = useState('');
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [drawerContent, setDrawerContent] = useState(null);
    const [showReadMore, setShowReadMore] = useState(null);  // Define showReadMore state here

    // Fetch assignments data from the backend API with cache-busting query parameter
    useEffect(() => {
        const fetchAssignments = async () => {
            try {
                // Add timestamp to the API request to bypass cache
                const response = await fetch(`https://kconsult.se/server/assignments.json?t=${new Date().getTime()}`);  // Cache-busting query parameter
                const data = await response.json();
                setAssignmentsData(data);  // Set the fetched data to state
            } catch (error) {
                console.error('Error fetching assignments:', error);
            }
        };
        fetchAssignments();
    }, []);  // Empty dependency array ensures this runs once on component mount

    // Handle category filter changes
    const handleCategoryChange = (event) => {
        const { name, checked } = event.target;
        if (checked) {
            setSelectedCategories([...selectedCategories, name]);
        } else {
            setSelectedCategories(selectedCategories.filter(category => category !== name));
        }
    };

    // Handle job type filter changes
    const handleJobTypeChange = (event) => {
        setSelectedJobType(event.target.value);
    };

    // Handle location filter changes
    const handleLocationChange = (event) => {
        setSelectedLocation(event.target.value);
    };

    const filteredAssignments = assignmentsData.filter((assignment) => {
        const categoryMatch = selectedCategories.length === 0 || selectedCategories.includes(assignment.category);
        const searchMatch = (assignment.title && assignment.title.toLowerCase().includes(searchQuery.toLowerCase())) ||
                            (assignment.description && assignment.description.toLowerCase().includes(searchQuery.toLowerCase())) ||
                            (assignment.id && assignment.id.includes(searchQuery));
        const jobTypeMatch = !selectedJobType || assignment.type === selectedJobType;
        const locationMatch = !selectedLocation || assignment.location === selectedLocation;

        return categoryMatch && searchMatch && jobTypeMatch && locationMatch;
    });

    const availableCategories = [...new Set(assignmentsData.map(assignment => assignment.category))];
    const availableLocations = [...new Set(assignmentsData.map(assignment => assignment.location))];

    const openDrawer = (assignment) => {
        setDrawerContent(assignment);
        setDrawerOpen(true);
    };

    const closeDrawer = () => {
        setDrawerOpen(false);
    };

    const toggleReadMore = (id) => {
        setShowReadMore(showReadMore === id ? null : id);
    };

    const cleanTitle = (title) => {
        return title.replace(/ - .+?#\d+$/, '').trim();
    };

    return (
        <div className="assignments-container">
            <div className="filters">
                <h4>Areas</h4>
                <form>
                {availableCategories.map((category) => (
        <div className="filter-option" key={category}>
            <input 
                type="checkbox" 
                id={category} 
                name={category} 
                onChange={handleCategoryChange} 
            />
            <label htmlFor={category}>{category}</label>
        </div>
    ))}

                    <div className="filter-option">
                        <label htmlFor="location">Location</label>
                        <select id="location" value={selectedLocation} onChange={handleLocationChange}>
                            <option value="">All</option>
                            {availableLocations.map((location) => (
                                <option key={location} value={location}>{location}</option>
                            ))}
                        </select>
                    </div>

                    <div className="filter-option">
                        <label htmlFor="jobType">Job Type</label>
                        <select id="jobType" value={selectedJobType} onChange={handleJobTypeChange}>
                            <option value="">All</option>
                            <option value="Onsite">Onsite</option>
                            <option value="Remote">Remote</option>
                            <option value="Hybrid">Hybrid</option>
                        </select>
                    </div>

                    <input 
                        type="text" 
                        className="search-box" 
                        placeholder="Search by title, description, or ID" 
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                </form>
            </div>

            <div className="assignments-list">
                <div className="assignment-list-header">
                    <h2>Available Assignments</h2>
                    <span className="assignment-count">
                        {filteredAssignments.length} assignment{filteredAssignments.length !== 1 ? 's' : ''} found
                    </span>
                </div>

                {filteredAssignments.length > 0 ? (
                    filteredAssignments.map((assignment) => (
                        <div className="assignment-card" key={assignment.id}>
                            <span className="assignment-tag">{assignment.category}</span>
                            <span className={`job-type ${assignment.type.toLowerCase()}`}>{assignment.type}</span>
                            <h3>{cleanTitle(assignment.title)}</h3>
                            <p><strong>ID:</strong> {assignment.id}</p>
                            <p><strong>Location:</strong> {assignment.location}</p>
                            <p>{showReadMore === assignment.id ? assignment.description : `${assignment.description.substring(0, 100)}...`}</p>

                            {showReadMore === assignment.id && (
                                <div className="expanded-content">
                                    <h4>Responsibilities</h4>
                                    <ul>
                                        {assignment.details && assignment.details.responsibilities.map((task, index) => (
                                            <li key={index}>{task}</li>
                                        ))}
                                    </ul>
                                    <h4>Requirements</h4>
                                    <ul>
                                        {assignment.details && assignment.details.requirements.map((requirement, index) => (
                                            <li key={index}>{requirement}</li>
                                        ))}
                                    </ul>
                                    <h4>Meritorious</h4>
                                    <ul>
                                        {assignment.details && assignment.details.meritorious.map((merit, index) => (
                                            <li key={index}>{merit}</li>
                                        ))}
                                    </ul>
                                    <p><strong>Start:</strong> {assignment.start}</p>
                                    <p><strong>End:</strong> {assignment.end}</p>
                                    <p><strong>Extension:</strong> {assignment.extension}</p>
                                    <p><strong>Closing:</strong> {assignment.closing}</p>
                                </div>
                            )}

                            <div className="assignment-buttons">
                                <button className="read-more-btn" onClick={() => toggleReadMore(assignment.id)}>
                                    {showReadMore === assignment.id ? 'Show Less' : 'Read More'}
                                </button>
                                <button className="apply-btn" onClick={() => openDrawer(assignment)}>Apply</button>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className="no-results">
                        <p>No assignments found. Try adjusting your filters or search query.</p>
                    </div>
                )}
            </div>

            <SlidingDrawer isOpen={drawerOpen} closeDrawer={closeDrawer} assignment={drawerContent} />
        </div>
    );
};

export default Assignments;
