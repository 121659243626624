// src/components/HomeSection.js

import React from 'react';
import './HomeSection.css';
import image1 from '../assets/1.jpg';
import image2 from '../assets/2.jpg';

const HomeSection = () => {
    return (
        <div>
            <section className="hero-text-section">
                <div className="hero-content">
                    <h1>Lead the Future. Empower Your Vision.</h1>
                    <p>Discover the possibilities of driving innovation and achieving excellence with tailored solutions that turn your ambitions into reality.</p>
                    <div className="divider"></div>
                </div>
            </section>

            <section className="home-section">
                <div className="row align-items-center home-row">
                    <div className="col-lg-6 col-md-12 image-container">
                        <img src={image1} alt="Professional Consulting" className="home-image" />
                    </div>
                    <div className="col-lg-6 col-md-12 home-text">
                        <p>Kolgjini Consulting is a dedicated team of IT specialists and consultants who deliver tailored solutions in areas such as Agile development, DevOps, fullstack engineering, quality assurance, and AI-powered data analysis. Our goal is to empower businesses by providing expertise that drives growth and innovation.</p>
                        <p>We prioritize building long-lasting partnerships with our clients by offering highly skilled professionals who bring both strategic insight and technical excellence to each project.</p>
                        <p>Leveraging years of experience in IT and business consultancy, we assist clients in identifying key challenges and opportunities, delivering solutions that optimize performance, reduce costs, and create a competitive edge.</p>
                    </div>
                </div>

                <div className="row align-items-center home-row reverse-row">
                    <div className="col-lg-6 col-md-12 image-container order-md-2">
                        <img src={image2} alt="Consulting Environment" className="home-image" />
                    </div>
                    <div className="col-lg-6 col-md-12 home-text order-md-1">
                        <p>At Kolgjini Consulting, we understand the importance of matching the right talent to the right project. By identifying candidates with the expertise and mindset needed to succeed, we help businesses build teams that excel in fast-paced, complex environments.</p>
                        <p>Our commitment to maintaining strong relationships with our clients and consultants is at the core of everything we do. We focus on delivering value through innovation, integrity, and collaboration.</p>
                        <p>By staying on the cutting edge of technology and industry trends, Kolgjini Consulting is equipped to help businesses navigate the challenges of today’s digital landscape and capitalize on future opportunities.</p>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default HomeSection;
