// src/components/Navbar.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import logo from '../assets/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faBriefcase, faGraduationCap } from '@fortawesome/free-solid-svg-icons';

const Navbar = () => {
    const [navbarScrolled, setNavbarScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setNavbarScrolled(true);
            } else {
                setNavbarScrolled(false);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <header>
            <nav className={`navbar navbar-expand-lg custom-navbar ${navbarScrolled ? 'scrolled' : ''}`}>
                <div className="container">
                    <Link className="navbar-brand" to="/">
                        <img src={logo} alt="Kolgjini Consulting Logo" className="logo" loading="lazy" />
                    </Link>
                    <button 
                        className="navbar-toggler" 
                        type="button" 
                        data-bs-toggle="collapse" 
                        data-bs-target="#navbarNav" 
                        aria-controls="navbarNav" 
                        aria-expanded="false" 
                        aria-label="Toggle navigation menu"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav ms-auto">
                            <li className="nav-item">
                                <Link className="nav-link" to="/">
                                    <FontAwesomeIcon icon={faHome} className="nav-icon" /> Home
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/assignments">
                                    <FontAwesomeIcon icon={faBriefcase} className="nav-icon" /> Assignments
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/career">
                                    <FontAwesomeIcon icon={faGraduationCap} className="nav-icon" /> Career
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    );
};

export default Navbar;
